<template>
  <v-row v-if="data" style="max-width: 100%" justify="center">
    <v-col cols="12" v-if="data.type === 'image'">
      <v-img
        :title="data.name"
        style="position: relative"
        :width="width"
        :aspect-ratio="aspectRatio"
        :lazy-src="`${this.$mediapoolUrl}/${data.subDir}/${data.uuid}-100.${data.extension}`"
        :src="`${this.$mediapoolUrl}/${data.subDir}/${data.uuid}-400.${data.extension}`"
      ></v-img>

      <span v-if="showCaption" class="caption">
        {{ data.name }}
        <span v-if="data.description"> — {{ data.description }}</span>
      </span>
    </v-col>
  </v-row>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "MediaItemPreview",

  props: ["uuid", "showCaption", "aspectRatio", "width"],

  data() {
    return {
      fileBaseUrl: this.$apiBaseURL + "/public/uploads",
      fetchinData: false, // should be true when fetching data
      data: [],
      itemToDelete: null,
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      this.fetchinData = true;
      const response = await this.getRequest(`mediapool/files/${this.uuid}`);
      this.fetchinData = false;
      if (response.status === 200) {
        this.data = response.data.data;
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
