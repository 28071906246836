<template>
  <div v-if="helpDataFetched">
    <v-container v-if="!edit" class="ma-0 pa-0">
      <v-row
        no-gutters
        class="align-center"
        style="border-bottom: 1px solid rgba(0, 0, 0, 0.05)"
      >
        <v-col
          v-if="!hideLabel"
          cols="3"
          class="font-weight-bold pa-2 black--text"
        >
          {{ itemLabel }}
        </v-col>

        <v-col
          :cols="inputElement === 'switch' || hideLabel ? 'auto' : '8'"
          class="pa-2"
        >
          <span
            v-if="inputType === 'color'"
            class="clickarea"
            @click="editItem()"
          >
            <v-chip class="ma-2 white--text" :color="rewrittenItem">
              {{ rewrittenItem }}
            </v-chip>
          </span>

          <!--  rewrittenItem returns 'is-array' when its an array.
          in this case, we use list-rendering for chips component -->
          <span
            v-else-if="rewrittenItem === 'is-array'"
            class="clickarea"
            @click="editItem()"
          >
            <template v-if="previewValue && previewValue.length > 0">
              <v-chip
                v-for="item in previewValue"
                :key="item.id"
                x-small
                class="ml-1 mb-1"
              >
                <span v-if="item.name">{{ item.name }}</span>
                <span v-else>{{ item }}</span>
              </v-chip>
            </template>

            <!-- <template v-else>
              <v-chip
                v-for="item in currentValue"
                :key="item.id"
                class="ml-1 mb-1"
              >
                {{ item.name }}
              </v-chip>
            </template> -->
          </span>

          <span
            v-else-if="inputType === 'file'"
            class="clickarea"
            :disabled="disabled"
            @click="editItem()"
          >
            <img
              v-if="previewValue"
              :src="previewValue + '?v=' + randomValue"
              style="max-height: 150px; max-width: 150px"
            />
          </span>

          <span
            v-else-if="inputType === 'media'"
            class="clickarea"
            @click="editItem()"
          >
            <span v-if="currentValue">
              <MediaItemPreview
                :uuid="currentValue"
                :showCaption="true"
                width="300"
                :key="mediaComponentKey"
              />
            </span>

            <span v-else>{{ $t("phrases.NoMedia") }}</span>
          </span>

          <span
            v-else-if="inputType === 'multiplemedia'"
            class="clickarea"
            @click="editItem()"
          >
            <span v-if="currentValue">
              <MultipleMediaItemPreview
                :items="currentValue"
                :showCaption="false"
                :deleteable="false"
                width="100"
                :key="mediaComponentKey"
              />
            </span>

            <span v-else>{{ $t("phrases.NoMedia") }}</span>
          </span>

          <span
            v-else-if="inputElement === 'textarea-md'"
            @click="editItem()"
            class="clickarea"
          >
            <vue-markdown
              v-if="currentValue"
              :source="currentValue"
            ></vue-markdown>

            <p
              v-if="hint"
              class="caption font-italic grey--text lighten-1--text"
              v-html="hint"
            ></p>
          </span>

          <span
            v-else-if="inputElement === 'input' && inputType === 'checkbox'"
          >
            <v-checkbox
              v-model="currentValue"
              color="green"
              true-value="1"
              false-value="0"
              class="align-self-center"
              :label="itemLabel"
              @change="patchItem()"
            ></v-checkbox>
          </span>

          <span v-else-if="inputElement === 'switch'">
            <v-switch
              dense
              v-model="currentValue"
              color="green"
              true-value="1"
              false-value="0"
              :hint="hint"
              :persistent-hint="hint ? true : false"
              :label="itemLabel"
              :disabled="disabled"
              @change="patchItem()"
            ></v-switch>
          </span>

          <span v-else-if="inputElement === 'switch-reverse'">
            <!-- Nur für einen Fall: Versand der Bestellbestätigung ausschalten (Orders)
            Warum: Variable heißt sendNoSetupMail. Und regulär führte das zu Verwirrungen -->
            <v-switch
              dense
              v-model="currentValue"
              color="green"
              true-value="0"
              false-value="1"
              :hint="hint"
              :persistent-hint="hint ? true : false"
              :label="itemLabel"
              :disabled="disabled"
              @change="patchItem()"
            ></v-switch>
          </span>

          <span
            v-else-if="inputElement === 'bonuspicker'"
            @click="editItem()"
            class="clickarea"
          >
            {{ getNameOfBonus() }}
          </span>

          <span
            v-else-if="inputElement === 'feedbackpicker'"
            @click="editItem()"
            class="clickarea"
          >
            {{ getNameOfFeedback() }}
          </span>

          <span
            v-else
            class="clickarea"
            :disabled="disabled"
            @click="editItem()"
          >
            <span v-if="previewValue">
              <span
                v-if="
                  typeof previewValue === 'object' &&
                  Object.keys(previewValue).length === 0
                "
              >
                -
              </span>

              <span v-else>
                {{ previewValue }}
              </span>
            </span>
            <span v-else> {{ rewrittenItem }}</span>
          </span>
        </v-col>

        <v-col
          cols="1"
          v-if="
            inputElement !== 'switch' &&
            inputElement !== 'switch-reverse' &&
            inputType !== 'checkbox' &&
            !isLocked
          "
          class="pa-2"
        >
          <v-btn
            fab
            x-small
            color="green"
            elevation="0"
            @click="editItem()"
            :disabled="disabled ? true : false"
            class="white--text"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else class="ma-0 pa-0">
      <v-row
        no-gutters
        class="py-1 align-center light-green lighten-5"
        style="border-bottom: 1px solid lightgray"
      >
        <v-col cols="3" class="font-weight-bold pa-2">
          {{ itemLabel }}
        </v-col>

        <v-col
          v-if="
            itemName === 'isActive' ||
            itemName === 'isJmp' ||
            itemName === 'renewalAutomatic' ||
            itemName === 'isAdditionalOnly' ||
            itemName === 'isAdditionalHighlight' ||
            (itemName === 'isDemoRequestable' && inputElement === 'select')
          "
          cols="7"
          class="pa-2"
        >
          <!-- 
            SONDERFALL: Wird immer verwendet, wenn itemName = isActive ist, da isActive immer Ja/Nein 1/0 ist.
            array MUST contain name + id 
          -->
          <v-select
            class="align-self-center"
            v-model="currentValue"
            :items="yesNoOptions"
            item-text="name"
            item-value="value"
            label="Auswahl"
          ></v-select>
        </v-col>

        <v-col v-else-if="inputElement === 'input'" cols="7" class="pa-2">
          <v-text-field
            v-if="inputType === 'text'"
            class="align-self-center p0 m0"
            v-model="currentValue"
            :type="inputType"
          ></v-text-field>

          <v-text-field
            v-else-if="inputType === 'email'"
            class="align-self-center p0 m0"
            v-model="currentValue"
            :type="inputType"
            :rules="[rules.email]"
          ></v-text-field>
          <v-text-field
            v-else-if="inputType === 'number'"
            class="align-self-center p0 m0"
            v-model="currentValue"
            :type="inputType"
          ></v-text-field>
          <v-text-field
            v-else-if="inputType === 'tel'"
            class="align-self-center p0 m0"
            v-model="currentValue"
            :type="inputType"
          ></v-text-field>
          <v-text-field
            v-else-if="inputType === 'url'"
            class="align-self-center p0 m0"
            v-model="currentValue"
            :type="inputType"
            :rules="[rules.url]"
          ></v-text-field>
          <v-text-field
            v-else-if="inputType === 'time'"
            class="align-self-center p0 m0"
            v-model="currentValue"
            :type="inputType"
          ></v-text-field>

          <span v-else-if="inputType === 'file'" style="width: 100%">
            <img
              v-if="previewValue"
              :src="previewValue + '?v=' + randomValue"
              :key="componentKey"
              style="max-height: 150px; max-width: 150px"
            />
            <v-file-input
              class="align-self-center p0 m0"
              v-model="fileValue"
              chips
              show-size
              label="File input"
            ></v-file-input>
          </span>

          <div v-else-if="inputType === 'color'">
            <!-- colorpicker -->
            <v-color-picker
              v-model="currentValue"
              flat
              hide-mode-switch
              type="hex"
              mode="hexa"
              elevation="5"
            ></v-color-picker>
          </div>

          <div v-else-if="inputType === 'checkbox'">
            <v-checkbox
              v-model="currentValue"
              class="align-self-center"
              :label="itemLabel"
            ></v-checkbox>
          </div>

          <p
            v-if="hint"
            class="caption font-italic grey--text lighten-1--text"
            v-html="hint"
          ></p>
        </v-col>

        <v-col v-else-if="inputElement === 'select'" cols="7" class="pa-2">
          <!-- array MUST contain name + id -->
          <v-select
            class="align-self-center"
            v-model="currentValue"
            :items="itemsToSelect"
            :item-text="itemTextUsed"
            :item-value="itemValueUsed"
            label="Auswahl"
          ></v-select>
        </v-col>

        <v-col v-else-if="inputElement === 'multiselect'" cols="7" class="pa-2">
          <!-- array MUST contain name + id -->
          <v-combobox
            v-model="currentValue"
            :items="itemsToSelect"
            :item-text="itemTextUsed"
            :item-value="itemValueUsed"
            :return-object="false"
            :search-input.sync="search"
            hide-selected
            label="Du kannst suchen"
            multiple
            small-chips
          >
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                v-if="item"
                v-bind="attrs"
                :input-value="selected"
                label
                small
              >
                <span class="pr-2">
                  {{ getItemName(item, itemsToSelect) }}
                </span>
                <v-icon small @click="parent.selectItem(item)">
                  $delete
                </v-icon>
              </v-chip>
            </template>
          </v-combobox>

          <!-- <v-select
            class="align-self-center"
            v-model="itemValue"
            :items="itemsToSelect"
            :item-text="itemTextUsed"
            :item-value="itemValueUsed"
            label="Auswahl"
            chips
            multiple
          >
          </v-select> -->
        </v-col>

        <v-col v-else-if="inputElement === 'textarea'" cols="7" class="pa-2">
          <v-textarea
            solo
            v-model="currentValue"
            name="input-7-1"
            label="Text"
            item-text="name"
            item-value="value"
          ></v-textarea>
        </v-col>

        <v-col v-else-if="inputElement === 'textarea-md'" cols="7" class="pa-2">
          <v-container>
            <!-- <SimpleMdeEditor v-model="currentValue" :key="editorComponentKey" /> -->
            <v-md-editor
              v-model="currentValue"
              height="400px"
              left-toolbar="h bold italic | ul ol"
              right-toolbar="preview fullscreen"
              :disabled-menus="['h/h1']"
              mode="edit"
            ></v-md-editor>
          </v-container>

          <p
            v-if="hint"
            class="caption font-italic grey--text lighten-1--text"
            v-html="hint"
          ></p>
        </v-col>

        <v-col v-else-if="inputElement === 'switch'" cols="7" class="pa-2">
          <v-switch v-model="currentValue" :label="itemLabel"></v-switch>
        </v-col>

        <v-col v-else-if="inputElement === 'datepicker'" cols="7" class="pa-2">
          <!-- Es gibt andere Wege: https://vuetifyjs.com/en/components/date-pickers/#orientation -->
          <v-date-picker
            v-model="currentValue"
            color="primary"
            elevation="5"
            full-width
            locale="de-de"
            first-day-of-week="1"
          ></v-date-picker>
        </v-col>

        <v-col
          v-else-if="inputElement === 'companypicker'"
          cols="7"
          class="pa-2"
        >
          <v-select
            v-if="allCompanies"
            class="align-self-center"
            label="Bitte wähle eine Firma"
            v-model="currentValue"
            item-value="id"
            :items="allCompanies"
          >
            <template slot="selection" slot-scope="data">
              <!-- HTML that describe how select should render selected items -->
              {{ data.item.name }}
            </template>

            <template slot="item" slot-scope="data">
              <!-- HTML that describe how select should render items when the select is open -->
              {{ data.item.name }}
            </template>
          </v-select>
        </v-col>

        <v-col v-else-if="inputElement === 'pagepicker'" cols="7" class="pa-2">
          <v-autocomplete
            v-model="currentValue"
            :items="allPages"
            item-value="id"
            :item-text="(item) => `${item.id} - ${item.title}`"
            outlined
            dense
            chips
            small-chips
            label="Bitte wähle Seite"
          >
            <template slot="selection" slot-scope="data">
              {{ data.item.id }} {{ data.item.title }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.id }} {{ data.item.title }}
            </template>
          </v-autocomplete>
        </v-col>

        <v-col
          v-else-if="inputElement === 'campaignpicker'"
          cols="7"
          class="pa-2"
        >
          <v-autocomplete
            v-model="currentValue"
            :items="allCampaigns"
            item-value="id"
            :item-text="(item) => `${item.id} - ${item.title}`"
            outlined
            dense
            chips
            small-chips
            label="Bitte wähle eine Kampagne"
          >
            <template slot="selection" slot-scope="data">
              {{ data.item.id }} {{ data.item.title }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.id }} {{ data.item.title }}
            </template>
          </v-autocomplete>
        </v-col>

        <v-col v-else-if="inputElement === 'bonuspicker'" cols="7" class="pa-2">
          <v-select
            v-if="allBoni"
            class="align-self-center"
            label="Bitte wähle einen Bonus"
            v-model="currentValue"
            item-value="id"
            :items="allBoni"
          >
            <template slot="selection" slot-scope="data">
              <!-- HTML that describe how select should render selected items -->
              {{ data.item.name }} (#{{ data.item.id }})
            </template>

            <template slot="item" slot-scope="data">
              <!-- HTML that describe how select should render items when the select is open -->
              {{ data.item.name }} (#{{ data.item.id }})
            </template>
          </v-select>
        </v-col>

        <v-col
          v-else-if="inputElement === 'feedbackpicker'"
          cols="7"
          class="pa-2"
        >
          <v-select
            v-if="allFeedbacks"
            class="align-self-center"
            label="Bitte wähle ein Feedback-Set"
            v-model="currentValue"
            item-value="id"
            :items="allFeedbacks"
          >
            <template slot="selection" slot-scope="data">
              <!-- HTML that describe how select should render selected items -->
              {{ data.item.name }} (#{{ data.item.id }})
            </template>

            <template slot="item" slot-scope="data">
              <!-- HTML that describe how select should render items when the select is open -->
              {{ data.item.name }} (#{{ data.item.id }})
            </template>
          </v-select>
        </v-col>

        <v-col
          v-else-if="inputElement === '' && inputType === 'media'"
          cols="7"
          class="pa-2"
        >
          <v-row>
            <v-col cols="12">
              <span v-if="currentValue">
                <MediaItemPreview
                  :uuid="currentValue"
                  :showCaption="true"
                  aspectRatio=""
                  width="300"
                  :key="mediaComponentKey"
                />
              </span>

              <v-text-field
                class="align-self-center p0 m0"
                v-model="currentValue"
                type="text"
                disabled
              ></v-text-field>

              <p
                v-if="hint"
                class="caption font-italic grey--text lighten-1--text"
                v-html="hint"
              ></p>
            </v-col>

            <v-col cols="12">
              <v-btn
                class="white--text mx-1"
                depressed
                fab
                x-small
                color="red"
                :disabled="currentValue ? false : true"
                @click="currentValue = null"
              >
                <v-icon dark> mdi-trash-can </v-icon>
              </v-btn>
              <v-btn
                depressed
                color="green"
                class="white--text"
                @click="
                  addMediaDialog = true;
                  componentKey += 1;
                "
              >
                {{ $t("plugins.mediapool.title") }}
                <v-icon right dark> mdi-folder-multiple-image </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          v-else-if="inputElement === '' && inputType === 'multiplemedia'"
          cols="7"
          class="pa-2"
        >
          <v-row>
            <v-col cols="12">
              <span v-if="currentValue">
                <MultipleMediaItemPreview
                  :items="currentValue"
                  :showCaption="false"
                  aspectRatio=""
                  width="150"
                  :key="mediaComponentKey"
                  :deleteable="true"
                  @deleteItem="deleteItemUuidInCurrentValue"
                />
              </span>

              <v-text-field
                class="align-self-center p0 m0"
                v-model="currentValue"
                type="text"
                disabled
              ></v-text-field>

              <p
                v-if="hint"
                class="caption font-italic grey--text lighten-1--text"
                v-html="hint"
              ></p>
            </v-col>

            <v-col cols="12">
              <v-btn
                depressed
                color="green"
                class="white--text"
                @click="
                  addMediaDialog = true;
                  componentKey += 1;
                "
              >
                {{ $t("plugins.mediapool.title") }}
                <v-icon right dark> mdi-folder-multiple-image </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="2"
          align-self="center"
          class="d-flex justify-center align-self-end pa-2"
        >
          <span class="align-self-end">
            <v-btn
              fab
              x-small
              color="green"
              elevation="0"
              @click="patchItem()"
              class="mr-1 white--text"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>

            <v-btn
              class="ma-0"
              fab
              x-small
              color="secondary"
              elevation="0"
              @click="abortEdit()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </span>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="addMediaDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn
            icon
            dark
            @click="addMediaDialog = false"
            :disabled="uploading ? true : false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title>
            {{ $t("plugins.mediapool.title") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <Mediapool
            :selectable="multiple ? 'multiple' : 'one'"
            :selectmode="true"
            :dialogMode="true"
            :key="componentKey"
            :items="currentValue"
            @itemsSelected="itemsSelected"
            @itemSelected="itemSelected"
            @itemAdded="itemAdded"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import VueMarkdown from "vue-markdown/src/VueMarkdown"; // neuer Weg (s. oben)
import Mediapool from "@/views/tech/mediapool/Mediapool";
import MediaItemPreview from "@/components/mediapool/MediaItemPreview";
import MultipleMediaItemPreview from "@/components/mediapool/MultipleMediaItemPreview";
export default {
  name: "Patcher",

  components: {
    VueMarkdown,
    MediaItemPreview,
    MultipleMediaItemPreview,
    Mediapool,
  },

  props: [
    "itemValue",
    "itemName",
    "route",
    "inputElement",
    "inputType",
    "selectOptions",
    "itemId",
    "switchLabel",
    "selectValueKey",
    "selectTextKey",
    "previewValue",
    "label",
    "disabled",
    "itemLabel",
    "isLocked",
    "hideLabel",
    "hint",
    "multiple",
  ],

  created: async function () {
    if (this.itemValueUsed === "" || !this.itemValueUsed) {
      this.itemValueUsed = "value";
    }

    if (this.itemTextUsed === "" || !this.itemTextUsed) {
      this.itemTextUsed = "name";
    }

    /* get needed data (eg for selections) via api to ref Var */

    if (this.inputElement === "companypicker") {
      /* fetch data for selects and id->name connection */
      let response = await this.getRequest("companies"); // await data from mixin
      this.allCompanies = response.data.data;
      this.helpDataFetched = true;
    } else if (this.inputElement === "pagepicker") {
      /* fetch data for selects and id->name connection */
      let response = await this.getRequest("pages"); // await data from mixin
      this.allPages = response.data.data;
      this.helpDataFetched = true;
    } else if (this.inputElement === "campaignpicker") {
      /* fetch data for selects and id->name connection */
      let response = await this.getRequest(
        "campaigns?filter[status]=waiting,paused,active"
      ); // await data from mixin
      this.allCampaigns = response.data.data;
      this.helpDataFetched = true;
    } else if (this.inputElement === "bonuspicker") {
      /* fetch data for selects and id->name connection */
      let response = await this.getRequest("scouting-boni"); // await data from mixin
      this.allBoni = response.data.data;
      this.allBoni.unshift({ id: 0, name: "Kein Bonus" });
      this.helpDataFetched = true;
    } else if (this.inputElement === "feedbackpicker") {
      /* fetch data for selects and id->name connection */
      let response = await this.getRequest("feedbackSets"); // await data from mixin
      this.allFeedbacks = response.data.data;
      this.allFeedbacks.unshift({
        id: 0,
        name: "Kein Feedback (nutze Standard)",
      });
      this.helpDataFetched = true;
    } else if (this.itemName === "type_id") {
      /* fetch data for selects and id->name connection */
      let response = await this.getRequest("products/types"); // await data from mixin
      this.productTypes = response.data.data;
      this.helpDataFetched = true;
    } else {
      this.helpDataFetched = true;
    }
  },

  data() {
    return {
      search: "",
      mediaComponentKey: 0,
      componentKey: 0,
      itemTextUsed: this.selectTextKey,
      itemValueUsed: this.selectValueKey,
      randomValue: (Math.random() + 1).toString(36).substring(7),
      helpDataFetched: false,
      edit: false,
      fileValue: null,
      currentValue: this.validateCurrentValue(),
      currentName: this.itemName,
      currentType: this.inputElement,
      colorTypes: ["hex"],
      allCompanies: [] /* for companyPicker */,
      allPages: [] /* for pagePicker */,
      allCampaigns: [] /* for campaignPicker */,
      allBoni: [] /* for boniPicker */,
      allFeedbacks: [] /* for feedbackPicker */,
      productTypes: this.getProductTypes,
      companyName: "",
      yesNoOptions: [
        {
          name: "Ja",
          value: "1",
        },
        {
          name: "Nein",
          value: "0",
        },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        url: (value) => {
          const pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
              "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
              "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
              "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
              "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
              "(\\#[-a-z\\d_]*)?$",
            "i"
          ); // fragment locator
          return pattern.test(value) || "Invalid URL";
        },
      },

      configs: {
        status: false,
        spellChecker: false,
        sanitize: true,
        toolbar: [
          "bold",
          "italic",
          "heading-1",
          "heading-2",
          "heading-3",
          "unordered-list",
          "ordered-list",
          "link",
          "preview",
        ],
      },

      addMediaDialog: false,
      uploading: false,
      selectedItemType: null,
      selectedItemExt: null,
      itemsSelected: [],
      itemSelectedUuid: null,
      mediaUuid: null,
    };
  },

  computed: {
    simpleArray() {
      let result = [];

      this.currentValue.forEach((item) => {
        let id = null;

        if (item.id) {
          id = item.id;
        } else {
          id = item;
        }

        result.push(id);
      });

      return result;
    },

    itemsToSelect() {
      return this.selectOptions; // prop
    },

    rewrittenItem: function () {
      /* Umschreiben bestimmter Werte direkt im Array.
        1/0 wird Ja/Nein etc. */

      let value = this.currentValue;

      if (Array.isArray(value)) {
        /* falls value ein Array ist, wird hier lediglich 'is-array' returned.
          In diesem Fall wird im Template mit Chips die Liste gerendert */
        return "is-array";
      }

      /* Ja/Nein */

      if (
        this.currentName === "isActive" ||
        this.currentName === "isJmp" ||
        this.currentName === "renewalAutomatic" ||
        this.currentName === "isAdditionalOnly" ||
        this.currentName === "isAdditionalHighlight" ||
        this.currentName === "isDemoRequestable"
      ) {
        if (value === "0" || value === 0) {
          value = "Nein";
        } else if (value === "1" || value === 1) {
          value = "Ja";
        }
      }

      if (value === "female") {
        value = "Weiblich";
      } else if (value === "male") {
        value = "Männlich";
      } else if (value === "diverse") {
        value = "Divers";
      } else if (!value) {
        value = "-";
      } else if (value === "active") {
        value = "Aktiv";
      }

      /* Status of Pitches */

      if (value === "isOpen") {
        value = "Offen";
      } else if (value === "isContacted") {
        value = "Kontaktiert";
      } else if (value === "isInterested") {
        value = "Interessiert";
      } else if (value === "isScheduled") {
        value = "Terminiert";
      } else if (value === "offerSent") {
        value = "Angebot gesendet";
      } else if (value === "isInterested") {
        value = "Interessiert";
      } else if (value === "isLost") {
        value = "Verloren";
      } else if (value === "isWon") {
        value = "Gewonnen";
      } else if (!value) {
        value = "-";
      }

      /* Special-Case: Get Companyname by ID */

      if (this.currentName === "companies_id") {
        let that = this;

        Object.keys(this.allCompanies).forEach(function (k) {
          if (that.allCompanies[k].id === value) {
            value = that.allCompanies[k].name;
          }
        });
      }

      /* Special-Case: Get Pagename by ID */
      if (this.currentName === "followUpCampaign_id") {
        let that = this;

        Object.keys(this.allCampaigns).forEach(function (k) {
          if (that.allCampaigns[k].id === value) {
            value = that.allCampaigns[k].title;
          }
        });
      }

      /* Special-Case: Get Pagename by ID */
      if (this.currentName === "pages_id") {
        let that = this;

        Object.keys(this.allPages).forEach(function (k) {
          if (that.allPages[k].id === value) {
            value = that.allPages[k].title;
          }
        });
      }

      /* Special-Case: Get Bonusname by ID */
      if (this.currentName === "scoutingBonusId") {
        let that = this;

        Object.keys(this.allBoni).forEach(function (k) {
          if (that.allBoni[k].id === value) {
            value = that.allBoni[k].title;
          }
        });
      }

      /* Special-Case: Get Feedbackname by ID */
      if (this.currentName === "feedbackSetId") {
        let that = this;

        Object.keys(this.allFeedbacks).forEach(function (k) {
          if (that.allFeedbacks[k].id === value) {
            value = that.allFeedbacks[k].title;
          }
        });
      }

      /* Special-Case: Get productType by ID */

      if (this.currentName === "type_id") {
        let that = this;
        Object.keys(this.productTypes).forEach(function (k) {
          if (that.productTypes[k].id === value) {
            value = that.productTypes[k].name;
          }
        });
      }

      return value;
    },
  },

  methods: {
    getItemName(item, items) {
      let name = "";
      // check, if item is an object
      if (typeof item === "object") {
        name = item.name;
      } else {
        // if not, get name from items
        Object.keys(items).forEach(function (k) {
          // "name" should be itemValueKey from prop
          if (items[k].id == item) {
            name = items[k].name;
          }
          if (items[k].name === item) {
            name = items[k].name;
          }
        });
      }

      return name;
    },

    validateCurrentValue() {
      /* Für das MD-Plugin ist es nötig, einen String zu haben.
      Auch wenn nichts da ist, muss itemValue ein String sein.
      Die Methode feuert also nur, wenn inputElement = "textarea-md" und itemValue kein String ist */
      if (
        this.inputElement === "textarea-md" &&
        typeof this.itemValue !== "string"
      ) {
        // turn into string
        return "";
      } else if (this.inputElement === "datepicker") {
        /* Unter bestimmten Umständen kann es vorkommen, dass ein Datum auf "0000-00-00" gespeichert wurde.
        Das wird hier abgefangen. */
        if (this.itemValue === "0000-00-00" || this.itemValue === null) {
          return null;
        }
      }

      return this.itemValue;
    },

    getNameOfBonus() {
      let name = null;
      let that = this;
      Object.keys(this.allBoni).forEach(function (k) {
        if (that.allBoni[k].id == that.currentValue) {
          name = that.allBoni[k].name;
        }
      });

      return name;
    },

    getNameOfFeedback() {
      let name = null;
      let that = this;
      Object.keys(this.allFeedbacks).forEach(function (k) {
        if (that.allFeedbacks[k].id == that.currentValue) {
          name = that.allFeedbacks[k].name;
        }
      });

      return name;
    },

    generateValueArray(currentValue) {
      let result = [];

      currentValue.forEach((item) => {
        let id = null;

        if (item.value) {
          id = item.value;
        } else {
          id = item;
        }

        result.push(id);
      });

      return result;
    },

    async getCompanieName(id) {
      let response = await this.getRequest("companies/" + id); // await data from mixin
      return response.data.data.name;
    },

    editItem() {
      if (!this.disabled && !this.isLocked) {
        this.edit = true;
        this.componentKey += 1;
      }
    },

    abortEdit() {
      this.edit = false;
      this.currentValue = this.itemValue;
    },

    async safeFile() {
      var formData = new FormData(); // leer
      formData.append("file", this.fileValue);

      let addIdToRoute = "";
      if (this.itemId) {
        addIdToRoute = "/" + this.itemId;
      }

      var body = null;
      this.preloader = true;
      let response = await this.postRequest(
        `${this.route}${addIdToRoute}`,
        body,
        formData
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$emit("emit", {
          name: "data",
          value: response.data.data,
        });

        this.filePreviewKey += 1;

        this.edit = false;
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde geupdated!";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async safeData() {
      const params = new URLSearchParams();
      params.append(this.itemName, this.currentValue);

      let addIdToRoute = "";
      if (this.itemId) {
        addIdToRoute = "/" + this.itemId;
      }

      this.preloader = true;
      let response = await this.patchRequest(
        `${this.route}${addIdToRoute}`,
        params
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$emit("emit", {
          action: "itemPatched",
          name: this.itemName,
          value: this.currentValue,
          response: response.data.data,
        });

        /* ARRAY SONDERFÄLLE
          Da ich für Arrays nur die Ids übergebe, muss ich für die Reaktivität das currentValue aus dem Response verwenden.
          Nur im Response ist die id/name Kombi drin, die ich für die Ausgabe brauche
          */
        if (this.itemName === "branches") {
          this.currentValue = response.data.data.branches;
        } else if (this.itemName === "categories") {
          this.currentValue = response.data.data.categories;
        } else if (this.itemName === "jmpCategories") {
          this.currentValue = response.data.data.jmpCategories;
        }

        this.edit = false;
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde geupdated!";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    patchItem() {
      // aka safe

      if (this.currentValue && this.currentValue.hex) {
        this.currentValue = this.currentValue.hex;
      }

      if (this.inputType === "file") {
        this.safeFile();
      } else {
        this.safeData();
      }
    },

    itemSelected(data) {
      // SINGLE-item selected in mediapool
      this.addMediaDialog = false;
      this.currentValue = data.uuid;
      this.mediaComponentKey += 1;
    },

    itemAdded(data) {
      // MULTIPLE-item selected in mediapool

      if (
        this.currentValue === "" ||
        this.currentValue === null ||
        !this.currentValue ||
        this.currentValue === undefined
      ) {
        this.currentValue = data.uuid;
      } else {
        this.currentValue += "," + data.uuid;
      }
      this.mediaComponentKey += 1;
    },

    deleteItemUuidInCurrentValue(uuid) {
      let currentValueArray = this.currentValue.split(",");
      let index = currentValueArray.indexOf(uuid);
      if (index > -1) {
        currentValueArray.splice(index, 1);
      }
      this.currentValue = currentValueArray.join(",");
      this.mediaComponentKey += 1;
    },
  },

  mixins: [apiRequest],
};
</script>

<style scoped type="scss">
.clickarea {
  width: auto;
  cursor: pointer;
  position: relative;
  padding: 5px 40px 5px 5px;
}

.clickarea:hover .editbutton {
  background-color: rgba(92, 181, 94, 0.2);
}
</style>
