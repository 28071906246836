<template>
  <v-container>
    <v-btn
      v-if="lastItem"
      tile
      color="primary"
      @click="$emit('goBack', lastItem)"
    >
      <v-icon left> mdi-backspace </v-icon>
      Zurück
    </v-btn>
  </v-container>
</template>

<script>
import api from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";

export default {
  name: "DialogModeNavi",
  components: {},
  data() {
    return {
      history: [],
      lastItem: null,
    };
  },
  created: function () {
    this.history = this.$store.getters["mediapoolHistory/history"];
    this.lastItem = this.history[this.history.length - 1];
  },
  watch: {
    // watch store mediapoolHistory/history for changes
    history: function () {
      this.history = this.$store.getters["mediapoolHistory/history"];
      this.lastItem = this.history[this.history.length - 1];
    },
  },
  mixins: [api, helpers],
};
</script>
