<template>
  <span>
    <v-card>
      <v-img
        v-if="item.type === 'image'"
        height="250"
        :lazy-src="`${this.$mediapoolUrl}/${item.subDir}/${item.uuid}-100.${item.extension}`"
        :src="`${this.$mediapoolUrl}/${item.subDir}/${item.uuid}-400.${item.extension}`"
        @click="editMediaDialog = true"
        style="cursor: pointer"
      ></v-img>

      <v-btn
        v-if="selectable === 'one'"
        tile
        color="green"
        dark
        width="100%"
        @click="useItem()"
      >
        <v-icon left> mdi-check </v-icon>
        Auswählen
      </v-btn>

      <template v-else-if="selectable === 'multiple'">
        <v-btn
          tile
          color="green"
          class="white--text"
          width="100%"
          @click="addItem()"
          :disabled="alreadySelected()"
        >
          <v-icon left> mdi-plus </v-icon>
          Auswählen
        </v-btn>
      </template>

      <v-card-title>{{ item.name }}</v-card-title>

      <v-card-text>
        {{ item.description }}
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-text class="caption">
        Typ: {{ item.type }}<br />
        Ext: {{ item.extension }}<br />
        Hochgeladen: {{ item.created }} von {{ item.userName }}
      </v-card-text>

      <v-card-actions v-if="!selectmode">
        <v-btn
          fab
          depressed
          x-small
          color="red"
          class="white--text"
          @click="deletePrompt(item.uuid)"
        >
          <v-icon> mdi-trash-can </v-icon>
        </v-btn>

        <v-spacer />

        <v-btn
          fab
          depressed
          x-small
          color="green"
          class="white--text"
          @click="editMediaDialog = true"
        >
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <AreYouSureDialog
      :title="$t('dialogs.DeletePrompt.title')"
      :text="$t('dialogs.DeletePrompt.text')"
      :dialog="safeguardingDialog"
      @dialogEmit="safeguarding"
    />

    <v-dialog
      v-model="editMediaDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn
            icon
            dark
            @click="editMediaDialog = false"
            :disabled="uploading ? true : false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title> Ein Medium bearbeiten </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <EditMedia
            :item="item"
            :key="componentKey"
            @uploaded="mediaUploaded"
            @progress="progress"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import api from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog";
import EditMedia from "@/components/mediapool/EditMedia";
export default {
  name: "MediaCard",

  components: {
    AreYouSureDialog,
    EditMedia,
  },

  data() {
    return {
      componentKey: 0,
      editMediaDialog: false,
      uploading: false,
      fileBaseUrl: this.$apiBaseURL + "/public/uploads",
      patchRouteMedia: ``,
      safeguardingDialog: false,
      itemToDelete: null,
      fetchinData: false, // should be true when fetching data
    };
  },

  props: ["item", "items", "permissions", "selectable", "selectmode", "index"],

  created: function () {},

  methods: {
    deletePrompt(uuid) {
      this.itemToDelete = uuid;
      this.safeguardingDialog = true;
    },

    useItem() {
      // single item use (in selectmode)
      // emit back to slice component
      // not multiple
      this.$emit("itemSelected", {
        uuid: this.item.uuid,
        extension: this.item.extension,
        type: this.item.type,
      });
    },

    addItem() {
      // multiple item use (in selectmode)
      // emit back to slice component
      this.$emit("itemAdded", {
        uuid: this.item.uuid,
        id: this.item.id,
        extension: this.item.extension,
        type: this.item.type,
      });
    },

    progress(value) {
      this.uploading = value;
    },
    mediaUploaded(value) {
      console.log(value);
    },

    safeguarding(value) {
      this.safeguardingDialog = false;

      if (value) {
        this.deleteItem();
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },

    async deleteItem() {
      this.fetchinData = true;
      const response = await this.deleteRequest(
        `mediapool/files/${this.itemToDelete}`
      );
      this.fetchinData = false;

      if (response.status === 200) {
        this.$emit("itemDeleted", this.index);
      }
    },

    alreadySelected() {
      if (this.items) {
        const itemsArr = this.items.split(","); // this.items to array
        // find this.item in itemsArray
        const found = itemsArr.find((element) => element === this.item.uuid);
        if (found) {
          return true;
        }
      }
      return false;
    },
  },

  computed: {},

  mixins: [api, helpers],
};
</script>
