<template>
  <v-container>
    <v-form ref="form" v-model="isFormValid" v-on:submit.prevent="safeData()">
      <v-row>
        <v-col cols="12">
          <v-file-input
            ref="file"
            v-on:change="handleFileUpload"
            :disabled="progess ? true : false"
            :rules="[rules.required]"
          ></v-file-input>
        </v-col>

        <v-col cols="12">
          <v-text-field
            label="Name*"
            v-model="name"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-textarea label="Beschreibung" v-model="description"></v-textarea>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field label="Copyrights" v-model="copyrights"></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field label="Quelle" v-model="source"></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            :label="$t('phrases.Keywords')"
            v-model="keywords"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-checkbox
            v-model="uploadSafeguarding"
            label="Ich verfüge über die benötigten Rechte diese Datei zu verwenden.*"
            :rules="[rules.required]"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-btn
        depressed
        color="primary"
        :disabled="!isFormValid ? true : false"
        :loading="progess"
        v-on:click="submitFile()"
      >
        Upload
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import store from "@/store";
import axios from "axios";
export default {
  data() {
    return {
      isFormValid: false,
      file: "",
      name: "",
      copyrights: "",
      source: "",
      keywords: null,
      description: "",
      uploadSafeguarding: false,
      progess: false,
      rules: {
        required: (value) => !!value || this.$t("required"),
      },
    };
  },

  props: ["parentCategoryUuid", "permissions", "mediapoolUuid"],

  methods: {
    submitFile() {
      this.progess = true;
      this.$emit("progress", true);
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("categoryUuid", this.parentCategoryUuid);
      formData.append("name", this.name);
      formData.append("copyrights", this.copyrights);
      formData.append("source", this.source);
      formData.append("description", this.description);
      formData.append("keywords", this.keywords);

      const url = `mediapool/files`;

      const that = this;

      axios
        .post(url, formData, {
          headers: {
            Token: store.getters["auth/token"],
          },
        })
        .then(function(response) {
          that.$emit("uploaded", response);
          that.progess = false;
          that.file = "";
        })
        .catch(function(error) {
          that.$emit("uploaded", error);
          that.progess = false;
        });
    },

    handleFileUpload(file) {
      this.file = file;
    },
  },
};
</script>
