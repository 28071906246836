<template>
  <div>
    <v-row dense v-if="!fetchinData">
      <v-col cols="auto" v-for="item in data" :key="item.uuid">
        <div v-if="item.type === 'image'">
          <v-img
            :title="item.name"
            style="position: relative"
            :width="width"
            :aspect-ratio="aspectRatio"
            :lazy-src="`${mediapoolUrl}/${item.subDir}/${item.uuid}-100.${item.extension}`"
            :src="`${mediapoolUrl}/${item.subDir}/${item.uuid}-400.${item.extension}`"
          >
            <v-btn
              v-if="deleteable"
              class="white--text mx-1"
              depressed
              fab
              x-small
              color="red"
              @click="$emit('deleteItem', item.uuid)"
              style="position: absolute; top: 5px; right: 5px"
            >
              <v-icon dark> mdi-trash-can </v-icon>
            </v-btn>
          </v-img>

          <span v-if="showCaption" class="caption">
            {{ item.name }}
            <span v-if="item.description"> — {{ item.description }}</span>
          </span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "MultipleMediaItemPreview",

  props: ["items", "showCaption", "aspectRatio", "width", "deleteable"],

  data() {
    return {
      fileBaseUrl: this.$apiBaseURL + "/public/uploads",
      fetchinData: false, // should be true when fetching data
      data: [],
      itemsArray: [],
      itemToDelete: null,
      mediapoolUrl: this.$mediapoolUrl,
    };
  },

  created: function () {
    this.itemsArray = this.items.split(","); // turn string into array
    this.getImages();
  },

  methods: {
    async getData(uuid) {
      const response = await this.getRequest(`mediapool/files/${uuid}`);
      if (response.status === 200) {
        this.data.push(response.data.data);
      }
    },

    async getImages() {
      this.fetchinData = true;
      this.itemsArray.forEach((item) => {
        this.getData(item);
      });

      this.fetchinData = false;
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
