<template>
  <v-container>
    <PageHeader
      :pageTitle="$t('plugins.mediapool.title')"
      :pageDescription="$t('plugins.mediapool.description')"
      v-if="!selectmode"
    />

    <!-- if dialogMode -->
    <DialogModeNavibar v-if="dialogMode" @goBack="goBackDialogMode" />

    <v-row v-if="!fetchinData">
      <v-col>
        <!-- dialogMode: {{ dialogMode }}<br />
        parentCategoryUuid: {{ parentCategoryUuid }} -->
        <v-card tile class="my-2">
          <v-toolbar color="secondary" dark>
            <v-toolbar-title>Kategorien</v-toolbar-title>
            <v-spacer />
            <v-btn
              v-if="parentCategoryUuid"
              fab
              dark
              small
              elevation="0"
              color="white"
              class="secondary--text"
              :loading="loading"
              @click="addCategory()"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text v-if="data.categories.length > 0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('phrases.Search')"
              single-line
              hide-details
            ></v-text-field>

            <v-data-table
              :loading="fetchingData"
              :headers="headers"
              :items="data.categories"
              :search="search"
              calculate-widths
            >
              <template v-slot:[`item.name`]="props">
                <v-row v-if="props.item.isSystem !== '1'" no-gutters>
                  <v-col
                    md="auto"
                    class="pt-2 mr-1"
                    :style="pending ? 'opacity:.1' : ''"
                  >
                    <a
                      @click="openCategory(props.item.uuid)"
                      class="black--text"
                    >
                      {{ props.item.name }}
                    </a>
                  </v-col>

                  <v-col md="auto">
                    <v-edit-dialog
                      :return-value.sync="props.item.name"
                      @save="save('name', props.item.name, props.item.uuid)"
                    >
                      <v-btn
                        class="mx-1 editbutton"
                        fab
                        x-small
                        elevation="0"
                        :disabled="pending"
                      >
                        <v-icon x-small>mdi-pencil</v-icon>
                      </v-btn>

                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.name"
                          label="Edit"
                          single-line
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </v-col>
                </v-row>

                <span v-else>
                  {{ props.item.name }}
                </span>
              </template>

              <template v-slot:[`item.icon`]="{ item }">
                <v-btn
                  class="ma-1"
                  fab
                  dark
                  depressed
                  x-small
                  color="secondary"
                  :disabled="pending"
                  @click="openCategory(item.uuid)"
                >
                  <v-icon v-if="item.isSystem === '0'">
                    mdi-folder-open
                  </v-icon>
                  <v-icon v-else> mdi-folder-lock </v-icon>
                </v-btn>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      :disabled="item.isSystem === '0' ? false : true"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      :disabled="pending"
                      @click="
                        deleteItem(
                          item.uuid,
                          data.categories
                            .map(function (x) {
                              return x.uuid;
                            })
                            .indexOf(item.uuid)
                        )
                      "
                    >
                      <v-list-item-title>
                        {{ $t("controles.Delete") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-text v-else>
            {{ $t("phrases.NoCategories") }}
          </v-card-text>
        </v-card>

        <v-card v-if="parentCategoryUuid" tile class="my-2">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Medien</v-toolbar-title>

            <v-spacer />
            <v-btn
              fab
              dark
              small
              elevation="0"
              color="white"
              class="primary--text"
              @click="addMediaDialog = true"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text v-if="data.files.length > 0">
            <v-row v-if="data.files">
              <v-col
                cols="12"
                md="3"
                v-for="(item, index) in reversedMedia"
                :key="item.id"
              >
                <MediaCard
                  :item="item"
                  :items="items"
                  :index="index"
                  :selectable="selectable"
                  :selectmode="selectmode"
                  @itemSelected="$emit('itemSelected', $event)"
                  @itemAdded="$emit('itemAdded', $event)"
                  @itemDeleted="reversedMedia.splice(index, 1)"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text v-else>
            {{ $t("phrases.NoFiles") }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="addMediaDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn
            icon
            dark
            @click="addMediaDialog = false"
            :disabled="uploading ? true : false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title> Ein Medium hinzufügen </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <AddMedia
            :parentCategoryUuid="parentCategoryUuid"
            :key="componentKey"
            @uploaded="mediaUploaded"
            @progress="uploading = true"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader";
//import Patcher from "@/components/ui/Patcher";
import AddMedia from "@/components/mediapool/AddMedia";
import MediaCard from "@/components/mediapool/MediaCard";
import DialogModeNavibar from "@/components/mediapool/DialogModeNavi";

/* ! HINWEIS
Hier gibt es den dialogMode (true/false)
Wenn der MP als Dialog geöffnet wird, greift nicht der Router.
Ansonsten schon. */

export default {
  name: "MediaPool",

  components: {
    PageHeader,
    //Patcher,
    AddMedia,
    MediaCard,
    DialogModeNavibar,
  },

  data() {
    return {
      search: null,
      fetchingData: false,
      pending: false,
      fileBaseUrl: this.$apiBaseURL + "/public/uploads",
      addMediaDialog: false,
      loading: false,
      uploading: false,
      patchRoute: `/mediapool/categories/`,
      patchRouteMedia: ``,
      componentKey: 0,
      safeguardingDialog: false,
      pageTitle: "Medienpool",
      pageDescription: this.$t("plugins.mediapool.description"),
      fetchinData: false, // should be true when fetching dat
      data: [],
      itemToDelete: null,
      indexToDelete: null,
      parentCategoryUuid: null,
      headers: [
        { text: "", value: "icon", sortable: false, width: 40 },
        { text: "Name", value: "name" },
        { text: "", value: "actions", sortable: false, width: 40 },
      ],
    };
  },

  props: ["selectable", "selectmode", "dialogMode", "items"], // false,one,multiple ... Können Bilder zur Verwendung ausgewählt werden

  created: function () {
    if (!this.dialogMode) {
      this.parentCategoryUuid = this.$route.params.id;
    }
    // clear mediapool history
    this.$store.commit("mediapoolHistory/CLEAR_HISTORY");
    this.getData();
  },

  watch: {
    parentCategoryUuid: function () {
      // if change, get data
      this.getData();
    },

    $route: {
      handler: function () {
        if (!this.dialogMode) {
          /* nicht im dialog mode */
          this.parentCategoryUuid = this.$route.params.id;
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    goBackDialogMode() {
      this.$store.commit("mediapoolHistory/REMOVE_LAST_ITEM");

      const history = this.$store.getters["mediapoolHistory/history"];
      const prevItem = history[history.length - 1];
      this.parentCategoryUuid = prevItem;
      this.getData();
    },

    async deleteItem(uuid, index) {
      const userChoice = await this.$dialog.confirm({
        text: this.$t("dialogs.DeletePrompt.text"),
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        this.pending = true;
        const response = await this.deleteRequest(
          `mediapool/categories/${uuid}`
        );
        this.pending = false;

        if (response.status === 200) {
          this.data.categories.splice(index, 1);

          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Der Datensatz wurde gelöscht!";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      }
    },

    mediaUploaded(value) {
      this.addMediaDialog = false;
      this.componentKey += 1;
      // mutate
      this.data.files.unshift(value.data.data);
    },

    openCategory(uuid) {
      // only update the parentCategoryUuid
      // parentCategoryUuid has a watcher

      if (!this.dialogMode) {
        /* nicht im dialog-mode ... user router */
        this.$router.push({
          name: "tech.pages.mediapool.:id",
          params: { id: uuid },
        });
      } else {
        /* im dialog-mode */
        this.parentCategoryUuid = uuid;

        // add uuid to store (mediapoolHistory) mutations ADD_HISTORY
        this.$store.commit("mediapoolHistory/ADD_HISTORY", uuid);
      }
    },

    async getData() {
      this.fetchinData = true;

      let response = null;

      if (!this.parentCategoryUuid) {
        response = await this.getRequest(`mediapool`); // await data from mixin
      } else {
        if (this.dialogMode) {
          // get last item from store mediapoolHistory/ADD_HISTORY array
          const history = this.$store.getters["mediapoolHistory/history"];
          this.parentCategoryUuid = history[history.length - 1];
        }

        response = await this.getRequest(
          `mediapool/categories/${this.parentCategoryUuid}`
        );
      }
      this.fetchinData = false;

      if (response.status === 200) {
        this.data = response.data.data;
      }
    },

    async addCategory() {
      this.loading = true;

      let formData = new FormData();
      formData.append("parentCategoryUuid", this.parentCategoryUuid);

      let response = await this.postRequest(
        `mediapool/categories`,
        "",
        formData
      );

      this.loading = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemAddedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        this.selectedComponent = null;
        this.componentKey += 1;

        // todo Mutate
        this.data.categories.push(response.data.data);
      }

      this.loading = false;
    },

    async save(itemName, itemValue, itemUuid) {
      const params = new URLSearchParams();
      params.append(itemName, itemValue);
      this.preloader = true;
      const response = await this.patchRequest(
        `${this.patchRoute}${itemUuid}`,
        params
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde geupdated!";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    cancel() {},
    open() {},
    close() {},
  },

  computed: {
    reversedMedia: function () {
      return this.data.files;
    },
  },

  mixins: [api, helpers],
};
</script>
