<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row v-if="!fetchingData">
      <v-col cols="12" md="8">
        <v-card tile>
          <v-card-text>
            <table class="default-table">
              <tbody>
                <tr>
                  <td style="width: 150px">Titel</td>
                  <td>
                    <Patcher
                      :itemValue="data.title"
                      itemName="title"
                      :route="patchRoute"
                      inputElement="input"
                      inputType="text"
                      selectOptions=""
                      itemValueKey=""
                      itemTextKey=""
                      :previewValue="data.title"
                      @emit="
                        data.title = $event.value;
                        pageTitle = $event.value;
                      "
                    ></Patcher>
                  </td>
                </tr>

                <tr>
                  <td style="width: 150px">Bild, ohne Fehler</td>
                  <td>
                    <Patcher
                      :itemValue="data.goodImg_uuid"
                      itemName="goodImg_uuid"
                      itemLabel="Bild"
                      :hideLabel="false"
                      :route="patchRoute"
                      inputElement=""
                      inputType="media"
                      :previewValue="data.goodImg_uuid"
                      @emit="data.goodImg_uuid = $event.value"
                    ></Patcher>
                  </td>
                </tr>

                <tr>
                  <td style="width: 150px">Bild, mit Fehler</td>
                  <td>
                    <Patcher
                      :itemValue="data.badImg_uuid"
                      itemName="badImg_uuid"
                      itemLabel="Bild"
                      :hideLabel="false"
                      :route="patchRoute"
                      inputElement=""
                      inputType="media"
                      :previewValue="data.badImg_uuid"
                      @emit="
                        data.badImg_uuid = $event.value;
                        getBadImageUrl();
                      "
                    ></Patcher>
                  </td>
                </tr>

                <tr v-if="data.badImg_uuid">
                  <td style="width: 150px">Error-Map</td>
                  <td>
                    <div id="error-map" class="is-relative">
                      <img :src="badImageUrl" class="bad-image" />

                      <v-row
                        no-gutters
                        style="
                          height: 100%;
                          width: 100%;
                          position: absolute;
                          top: 0;
                          left: 0;
                        "
                      >
                        <v-col
                          cols="2"
                          v-for="index in tileMap"
                          :key="index"
                          @click="tileClicked(index)"
                          class="tile"
                        >
                          <div
                            v-if="errorMap.includes(index)"
                            class="tile-highlight"
                          ></div>
                        </v-col>
                      </v-row>
                    </div>

                    <v-btn
                      depressed
                      color="green"
                      @click="saveErrorMap()"
                      :disabled="errorMap.length < 1 ? true : false"
                      class="white--text my-4"
                    >
                      Fehler-Map speichern
                    </v-btn>

                    <br /><span class="caption">{{ errorMap }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <v-btn depressed color="primary" disabled>
              Vorschau
              <v-icon right dark> mdi-open-in-new </v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader.vue";
import Patcher from "@/components/ui/Patcher";
export default {
  name: "Game",

  components: {
    PageHeader,
    Patcher,
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  data() {
    return {
      pageTitle: null,
      pageDescription:
        "Hier werden alle Fehlersuch-Spiele verwaltet. Lade zwei Bilder hoch hoch und markiere die Fehlerstellen auf dem 2. Motiv.",
      itemId: this.$route.params.id,
      fetchingData: false,
      fetchingImage: false,
      data: null,
      patchRoute: `debugging_game/${this.$route.params.id}`,
      tileMap: 48, // 24 tiles
      errorMap: [],
      badImageUrl: null,
    };
  },

  methods: {
    async getBadImageUrl() {
      if (this.data.badImg_uuid) {
        this.fetchingImage = true;
        let response = await this.getRequest(
          `mediapool/files/${this.data.badImg_uuid}`
        );

        if (response.status === 200) {
          this.fetchingImage = false;
          this.badImageUrl = response.data.data.url;
        }
      }
    },

    tileClicked(index) {
      // check if index is already in errorMap. if not, add it
      if (this.errorMap.includes(index)) {
        // remove it
        this.errorMap = this.errorMap.filter((item) => item !== index);
      } else {
        // add it
        this.errorMap.push(index);
      }
      // sort the array ascending
      this.errorMap.sort((a, b) => a - b);
    },

    async saveErrorMap() {
      // this.errorMap to json
      const params = new URLSearchParams();
      params.append("errorMap", JSON.stringify(this.errorMap));

      let response = await this.patchRequest(this.patchRoute, params);

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde geupdated!";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(`debugging_game/${this.itemId}`);

      if (response.status === 200) {
        this.data = response.data.data;
        this.pageTitle = this.data.title;

        // set errorMap
        if (this.data.errorMap) {
          // remove brackets and spaces and convert values to numbers from string and convert to array
          this.errorMap = this.data.errorMap
            .replace(/[[\]']+/g, "")
            .split(",")
            .map((item) => parseInt(item));
        }

        this.getBadImageUrl();
      }

      this.fetchingData = false;
    },

    emittedAction(value) {
      if (value.action === "editItem") {
        this.$router.push({
          name: "tech.page-plugins.debugging_game.:id",
          params: { id: value.itemId },
        });
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>

<style scoped>
.is-relative {
  position: relative;
}
#error-map {
  width: 100%;
  height: auto;
  background-color: #eee;
}

.tile {
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.tile-highlight {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.5);
}

img.bad-image {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  object-fit: contain;
}
</style>
